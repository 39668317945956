body, html {
    font-family: "Arial", sans-serif;
    height: 100%;
    margin: 0;
}

.walletbutton {
    width: 180px;
    height: 30px;
    border-radius: 10px;
    border: solid #11f8fb;
    background-color: #c2fdfe;
    font-weight: 500;
    font-family: "Bitter", sans-serif;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color linear .5s;
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 83px;
}

.walletbutton:hover {
    background-color: #a2fcfd;
    box-shadow: 0px 0px 5px 1px black;
}


a {
    text-decoration: none;
    color: white;
}

p {
    margin: 0px;
}

#main {
    height: 100%;
    width: 100%;
}

#maincontainer {
    width: 100%;
    height: 100%;
}

//nav 

#navcontainer {
    width: 100%;
}

#bloodbanner {
    width: 100%;
    height: 70px;
    background-color: black;
    background-image: url("./files/blood.png") !important;
    background-position: center center;
    background-repeat: repeat;
    background-size: 70px;
}

#blackbanner {
    width: 100%;
    height: 70px;
    background-color: black;
}

//section 1

#section1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 75%;
    min-height: 760px;
}

#section1contentwrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 64%;
}

#section1text {
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}


.section1text {
    padding-left: 40%;
}

#section1title {
    font-family: "Bitter", sans-serif;
    font-weight: bolder;
    font-size: 70px;
    padding-bottom: 27px;
    text-shadow: 1px 0 #000;
}

#section1subtitle {
    font-family: "Bitter", sans-serif;
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 20px;
}

#section1body {
    width: 60%;
    font-size: 16px;
}

//section 1 images
#section1images {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.imagerow {
    display: flex;
    flex-direction: row;
    margin-left: 30px;
}

.facepic {
    width: 150px;
    height: 150px;
    background-size: cover;
    border-radius: 15px;
    margin: 18px;
    transition: ease-in-out .3s;
}

.facepic:hover {
    transform: scale(1.2);
    box-shadow: 0px 0px 15px 7px darkgray;

}

#face1{
    background-image: url("./files/emotions1-p-500.jpeg");
}

#face2 {
    background-image: url("./files/emotions2.jpg");
}

#face3 {
    background-image: url("./files/emotions3-p-500.jpeg");
}

#face4 {
    background-image: url("./files/emotions4-p-800.jpeg");
}

//section 1 mint

#mintwrapper {
    height: 36%;
    width: 100%;
    display: flex;
    justify-content: center;
}
//section 1 modal

#modalmessagetext {
    text-align: center;
    margin-top: 40px;
    font-weight: bold;
}

.countbutton {
    height: 30px;
    width: 30px;
    border-radius: 40px;
    border: solid #11f8fb;
    background-color: #c2fdfe;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color linear .5s;
    cursor: pointer;
}

.countbutton:hover {
    background-color: #a2fcfd;
    box-shadow: 0px 0px 5px 1px black;
}

#mintinput {
    position: absolute;
    text-align: center;
    left: 10%;
    bottom: 23%;
    height: 25px;
    font-size: 18px;;
    width: 40%;
}

.invalid-feedback {
    position: absolute;
    left: 10%;
    bottom: 16.4%;
    color: red;
    font-size: 14px;
}

#plus {
    position: absolute;
    right: 27%;
    bottom: 23%;
}

#minus {
    position: absolute;
    right: 8%;
    bottom: 23%;
}

#modalclose {
    position: absolute;
    right: 0%;
    top: 0%;
}

#modalcontainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.mintbutton {
    height: 30px;
    width: 160px;
    border-radius: 10px;
    border: solid #11f8fb;
    background-color: #c2fdfe;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color linear .5s;
    cursor: pointer;
    position: absolute;
    right: 20%;
    bottom: 0%;
}

#mintmodalopen {
    height: 60px;
    width: 200px;
    border-radius: 10px;
    border: solid #11f8fb;
    background-color: #c2fdfe;
    font-family: "Bitter", sans-serif;
    font-size: 28px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color linear .5s;
    cursor: pointer;
    margin-bottom: 20px;
}

#mintmodalopen:hover {
    background-color: #a2fcfd;
    box-shadow: 0px 0px 8px 1px black;
}

#openseabutton {
    height: 50px;
    width: 300px;
    border-radius: 10px;
    border: solid #414141;
    color: black;
    font-family: "Bitter", sans-serif;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color linear .5s;
    cursor: pointer;
    margin-bottom: 20px;
}

#opensealogo {
    background-image: url("./files/opensea-logo.png");
    background-size: cover;
    width: 40px;
    margin-left: 10px;
    height: 40px;
}

#openseabutton:hover {
    box-shadow: 0px 0px 8px 1px black;
}

#mintreal:hover {
    background-color: #a2fcfd;
    box-shadow: 0px 0px 5px 1px black;
}

#fakemint {
    opacity: .5;
    cursor: auto;
}

#countdowncontainer {
    width: 600px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#countdowntext {
    font-weight: bold;
    font-family: "Bitter", sans-serif;
    font-size: 23px;
    margin-bottom: 20px;
    text-align: center;
}

#time {
    font-weight: bold;
    font-family: "Bitter", sans-serif;
    font-size: 23px;
    margin-bottom: 20px;
}

@media only screen and (max-width: 900px) {

    #section1contentwrapper {
        flex-direction: column;
    }
    
    #section1 {
        height: auto;
    }

    #section1body {
        width: 80%;
        text-align: center;
    }

    #section1text {
        width: 100%;
        align-items: center;
        height: 70%;
        margin-bottom: 30px;
    }

    #section1images {
        width: 100%;
        align-items: center;
    }

    #section1title {
        margin-top: 37px;
    }

    #section1subtitle {
        font-size: 18px;
    }

    .section1text {
        padding-left: 0px;
    }

    .imagerow {
                margin-left: 0px;
    }

    #countdowntext {
        margin-top: 20px;
    }

    #countdowncontainer {
        width: 300px;
    }

    #mintmodalopen {
        margin-top: 20px;
    }
}

//section 2

#section2 {
    background-color: black;
    display: flex;
    flex-direction: row;
    height: 75%;
    min-height: 600px;
}

#cossimage {
    background-image: url("./files/coss.jpeg");
    width: 400px;
    height: 400px;
    background-size: cover;
    border-radius: 15px;
}

#cosscontainer {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#section2info {
    width: 50%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#section2title {
    font-family: "Bitter", sans-serif;
    font-weight: bolder;
    font-size: 30px;
    padding-bottom: 27px;
    text-shadow: 1px 0 #ffffff;
}

#section2body {
    font-size: 15px;
    width: 70%;
    margin-bottom: 40px;
}

.section2info {
    padding-left: 10%;
}

#section2socials {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 300px;
}

@media only screen and (max-width: 900px) {

    #section2 {
        flex-direction: column;
    }
    #cossimage {
        width: 150px;
        height: 150px;
    }

    #cosscontainer {
        width: 100%;
        justify-content: center;
        height: auto;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    #section2info {
        width: 100%;
        align-items: center;
    }

    #section2body {
        width: 75%;
        text-align: center;
    }

    .section2info {
        padding-left: 0px;
    }
}


//section 3
#section3 {
    display: flex;
    flex-direction: row;
    height: 75%;
    min-height: 600px;
}

#eyeimage {
    background-image: url("./files/mental-health-p-800.jpeg");
    width: 300px;
    height: 400px;
    background-size: cover;
    margin-left: 150px;
}

#eyecontainer {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#section3info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#section3title {
    font-family: "Bitter", sans-serif;
    font-weight: bolder;
    font-size: 40px;
    padding-bottom: 27px;
    text-shadow: 1px 0 #000;
    margin-top: 30px;

}

#section3body {
    font-size: 15px;
    width: 70%;
    margin-bottom: 10px;
}

.section3info {
    padding-left: 40%;
}

@media only screen and (max-width: 900px) {

    #section3 {
        flex-direction: column;
        height: auto;
    }
    #eyeimage {
        width: 200px;
        height: 400px;
        margin-left: 0px;
    }

    #eyecontainer {
        width: 100%;
        justify-content: center;
        height: auto;
        margin-top: 30px;
        margin-bottom: 50px;
    }

    #section3info {
        width: 100%;
        align-items: center;
    }

    #section3body {
        width: 75%;
        text-align: center;
    }

    .section3info {
        padding-left: 0px;
    }
}

//section 4
#section4 {
    background-color: black;
    width: 100%;
    height: 100px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}